import React from 'react'
import "./Cards.css"
import img1 from '../../img/image 1.png'
const Card = () => {
   
  return (
    <div className="row">
        <div className="col-3 " id='image' >
            <img src={img1} alt="img" />
        </div>
        <div className="col-4 details">
        <p>Ride Id : 002</p>
        <p>Origin Station : 20</p>
        <p> station_path : [20, 39, 40, 42, 54, 63, 72, 88, 98] </p>  
        <p>Date : 15th Feb 2022 16:33</p>       
        <p>Distance : 0</p>
         </div>
    </div>
  )
}

export default Card