import React from 'react'
import "./Header.css";
import rectangle from '../.././img/Rectangle.png'
const HeaderComponent = () => {
  return (
    <>
     <header>
       <div id="cont" className="container-fluid d-flex align-items-center  justify-content-between">
         <a href="#" id="logo">Edvora</a>
          <div id="cont__content">
            <p>Dhruv Singh <span className='ms-5'><img src={rectangle} /></span></p>
          </div>

       </div>
     </header>
    </>
  )
}

export default HeaderComponent