import React from 'react'
import HeaderComponent from './components/Header/HeaderComponent'
import "./App.css";
import Card from './components/Cards/Card';
const App = () => {
  //  const Rider=[
  //       {
  //           id: 001,
  //           origin_station_code: 23,
  //           station_path: [23,42,45,48,56,60,77,81,93],
  //           destination_station_code: 93,
  //           date: 1644924365,
  //           map_url: "url",
  //           state: 'Maharashtra',
  //           city: 'Panvel'
  //       },
  //       {
  //           id: 002,
  //           origin_station_code: 20,
  //           station_path: [20,39,40,42,54,63,72,88,98],
  //           destination_station_code: 98,
  //           date: 1644924365,
  //           map_url: "url",
  //           state: 'Maharashtra',
  //           city: 'Panvel'
  //       },
  //       {
  //           id: 003,
  //           origin_station_code: 13,
  //           station_path: [13,25,41,48,59,64,75,81,91],
  //           destination_station_code: 9,
  //           date: 1644924365,
  //           map_url: "url",
  //           state: 'Maharashtra',
  //           city: 'Panvel'
  //       },
  //   ]
  return (
     <>
     <HeaderComponent />
     <div className="container-fluid" id="app-cont">
       <ul className='d-flex align-items-center list'>
         <li><a href="#">Nearest rides</a></li>
         <li><a href="#">Upcoming rides (4)</a></li>
         <li><a href="#">Past rides (2)</a></li>
       </ul>

       <div id="cards-view">
        <Card />
        <Card />
        <Card />
       </div>
     </div>
     </>
  )
}

export default App